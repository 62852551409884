import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import './index.css';
import App from './App';
import DetailsStelle from "./components/Details/Details-Stelle";
import DetailsAusbildungsstelle from "./components/Details/Details-Ausbildungsstelle";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <div>
            <Router>
                <Routes>
                    <Route path="/" Component={App}/>
                    <Route path="/details/:id" Component={DetailsStelle}/>
                    <Route path="/ausbildungstelle/details/:id" Component={DetailsAusbildungsstelle}/>
                </Routes>
            </Router>
        </div>
    </React.StrictMode>
);
