import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {settings} from "../../settings";
import {Stellenangebot} from "../../types";
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';


const DetailsStelle = () => {

    const [stelle, setStelle] = useState<Stellenangebot>(); // [
    let {id} = useParams();

    useEffect(() => {
        fetch(settings.api.url + '/api/stellenangebote/public/' + id)
            .then((response) => response.json())
            .then((data) => {
                setStelle(data);
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [id]);

    return (
        <div>
            <Button label="Zurück zur Übersicht" icon="pi pi-arrow-left" className="p-button-secondary mb-5" onClick={() => window.history.back()}/>
            <Fieldset legend="Allgemein" className="mb-5">
                <dl>
                    <dt>Stellenbezeichnung:</dt>
                    <dd>{stelle?.bezeichnung}</dd>
                </dl>
                <dl>
                    <dt>Berufsfeld:</dt>
                    <dd>{stelle?.berufsfeld?.titel}</dd>
                </dl>
                <dl>
                    <dt>Arbeitgeber:</dt>
                    <dd>{stelle?.firma_name}</dd>
                </dl>
                <dl>
                    <dt>Beginn:</dt>
                    <dd>{stelle?.beginn}</dd>
                </dl>
                <dl>
                    <dt>Arbeitsort:</dt>
                    <dd>{stelle?.ort}</dd>
                </dl>
                <dl>
                    <dt>Bewerbung bis:</dt>
                    <dd>{stelle?.bewerbung_bis}</dd>
                </dl>
                <dl>
                    <dt>Bewerbungsweg:</dt>
                    <dd>{stelle?.bewerbungsart}</dd>
                </dl>

                {stelle?.verguetung !== null &&
                    <dl>
                        <dt>Vergütung:</dt>
                        <dd>{stelle?.verguetung}</dd>
                    </dl>
                }

                {stelle?.perspektive !== null &&
                    <dl>
                        <dt>Perspektiven:</dt>
                        <dd>{stelle?.perspektive}</dd>
                    </dl>
                }

            </Fieldset>

            <Fieldset legend="Stellenbeschreibung" className="mb-5">
                <p>{stelle?.beschreibung}</p>
            </Fieldset>

            <Fieldset legend="Wir bieten" className="mb-5">
                <p>{stelle?.angebot}</p>
            </Fieldset>


            <Fieldset legend="Anforderungen" className="mb-5">
                <p>{stelle?.anforderungen}</p>
            </Fieldset>

            <Fieldset legend="Kontakt" className="mb-5">
                <table className="desc-table">
                    <tbody>
                    {stelle?.contact_name != null &&
                        <tr>
                            <td>Ansprechpartner:</td>
                            <td>{stelle?.contact_name}</td>
                        </tr>
                    }
                    {stelle?.contact_phone != null &&
                        <tr>
                            <td>Telefon:</td>
                            <td>{stelle?.contact_phone}</td>
                        </tr>
                    }
                    {stelle?.contact_email != null &&
                        <tr>
                            <td>E-Mail:</td>
                            <td>{stelle?.contact_email}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </Fieldset>

            <Fieldset legend="Firma">
                <table className="desc-table">
                    <tbody>
                    <tr>
                        <td>Bezeichnung:</td>
                        <td>{stelle?.firma.bezeichnung}</td>
                    </tr>
                    {stelle?.firma?.anzahl_auszubildende != null &&
                        <tr>
                            <td>Anzahl Auszubildene:</td>
                            <td>{stelle?.firma.anzahl_auszubildende}</td>
                        </tr>
                    }
                    {stelle?.firma.anzahl_beschaeftigte != null &&
                        <tr>
                            <td>Anzahl Beschäftigte:</td>
                            <td>{stelle?.firma.anzahl_beschaeftigte}</td>
                        </tr>
                    }
                    {stelle?.firma.branche != null &&
                        <tr>
                            <td>Branche:</td>
                            <td>{stelle?.firma.branche}</td>
                        </tr>
                    }
                    {stelle?.firma.kurzprofil != null &&
                        <tr>
                            <td>Kurzprofil:</td>
                            <td>{stelle?.firma.kurzprofil}</td>
                        </tr>
                    }
                    {stelle?.firma.plz != null &&
                        <tr>
                            <td>PLZ:</td>
                            <td>{stelle?.firma.plz}</td>
                        </tr>
                    }
                    {stelle?.firma.ort != null &&
                        <tr>
                            <td>Ort:</td>
                            <td>{stelle?.firma.ort}</td>
                        </tr>
                    }
                    {stelle?.firma.strasse != null &&
                        <tr>
                            <td>Straße:</td>
                            <td>{stelle?.firma.strasse}</td>
                        </tr>
                    }
                    {stelle?.firma.web != null &&
                        <tr>
                            <td>Web:</td>
                            <td>{stelle?.firma.web}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </Fieldset>

        </div>
    );
}

export default DetailsStelle;
