import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {settings} from "../../settings";
import {Stellenangebot} from "../../types";
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';


const DetailsAusbildungsstelle = () => {

    const [stelle, setStelle] = useState<Stellenangebot>(); // [
    let {id} = useParams();

    function getCategoryTitle(type: number | undefined): string {
        switch (type) {
            case 0:
                return "Ausbildungsstelle";
            case 1:
                return "Praktikum";
            case 2:
                return "Schülerpraktikum";
            case 3:
                return "Duales Studium";
            default:
                return "Unbekannt";
        }
    }


    useEffect(() => {
        fetch(settings.api.url + '/api/ausbildungsstelle/public/' + id)
            .then((response) => response.json())
            .then((data) => {
                setStelle(data);
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [id]);

    return (
        <div>
            <Button label="Zurück zur Übersicht" icon="pi pi-arrow-left" className="p-button-secondary mb-5" onClick={() => window.history.back()}/>
            <Fieldset legend="Allgemein" className="mb-5">
                <table className="desc-table">
                    <tbody>
                    <tr>
                        <td>Bezeichnung:</td>
                        <td>{stelle?.titel}</td>
                    </tr>
                    <tr>
                        <td>Typ:</td>
                        <td>{getCategoryTitle(stelle?.category)}</td>
                    </tr>
                    <tr>
                        <td>Berufsfeld:</td>
                        <td>{stelle?.berufsfeld?.titel}</td>
                    </tr>
                    <tr>
                        <td>Ausbildungsbetrieb:</td>
                        <td>{stelle?.firma_name}</td>
                    </tr>
                    {stelle?.ausbildungsbeginn != null &&
                        <tr>
                            <td>Ausbildungsbeginn:</td>
                            <td>{stelle?.ausbildungsbeginn}</td>
                        </tr>
                    }
                    {stelle?.ausbildungsort != null &&
                        <tr>
                            <td>Ausbildungsort:</td>
                            <td>{stelle?.ausbildungsort}</td>
                        </tr>
                    }
                    {stelle?.anzahl_plaetze != null &&
                        <tr>
                            <td>Anzahl Ausbildungsplätze:</td>
                            <td>{stelle?.anzahl_plaetze}</td>
                        </tr>
                    }
                    {stelle?.bewerbung_ab != null &&
                        <tr>
                            <td>Bewerbung ab:</td>
                            <td>{stelle?.bewerbung_ab}</td>
                        </tr>
                    }
                    {stelle?.bewerbung_bis != null &&
                        <tr>
                            <td>Bewerbung bis:</td>
                            <td>{stelle?.bewerbung_bis}</td>
                        </tr>
                    }
                    {stelle?.bewerbungsart != null &&
                        <tr>
                            <td>Bewerbungsweg:</td>
                            <td>{stelle?.bewerbungsart}</td>
                        </tr>
                    }
                    {stelle?.verguetung != null &&
                        <tr>
                            <td>Vergütung:</td>
                            <td>{stelle?.verguetung}</td>
                        </tr>
                    }
                    {stelle?.perspektive != null &&
                        <tr>
                            <td>Perspektiven:</td>
                            <td>{stelle?.perspektive}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </Fieldset>


            <Fieldset legend="Ausbildungsinhalte" className="mb-5">
                <p>{stelle?.ausbildungsinhalte}</p>
            </Fieldset>


            <Fieldset legend="Anforderungen" className="mb-5">
                <p>{stelle?.anforderungen}</p>
            </Fieldset>

            <Fieldset legend="Kontakt" className="mb-5">
                <table className="desc-table">
                    <tbody>
                    {stelle?.contact_name != null &&
                        <tr>
                            <td>Ansprechpartner:</td>
                            <td>{stelle?.contact_name}</td>
                        </tr>
                    }
                    {stelle?.contact_phone != null &&
                        <tr>
                            <td>Telefon:</td>
                            <td>{stelle?.contact_phone}</td>
                        </tr>
                    }
                    {stelle?.contact_email != null &&
                        <tr>
                            <td>E-Mail:</td>
                            <td>{stelle?.contact_email}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </Fieldset>

            <Fieldset legend="Firma">
                <table className="desc-table">
                    <tbody>
                    <tr>
                        <td>Bezeichnung:</td>
                        <td>{stelle?.firma.bezeichnung}</td>
                    </tr>
                    {stelle?.firma?.anzahl_auszubildende != null &&
                        <tr>
                            <td>Anzahl Auszubildene:</td>
                            <td>{stelle?.firma.anzahl_auszubildende}</td>
                        </tr>
                    }
                    {stelle?.firma.anzahl_beschaeftigte != null &&
                        <tr>
                            <td>Anzahl Beschäftigte:</td>
                            <td>{stelle?.firma.anzahl_beschaeftigte}</td>
                        </tr>
                    }
                    {stelle?.firma.branche != null &&
                        <tr>
                            <td>Branche:</td>
                            <td>{stelle?.firma.branche}</td>
                        </tr>
                    }
                    {stelle?.firma.kurzprofil != null &&
                        <tr>
                            <td>Kurzprofil:</td>
                            <td>{stelle?.firma.kurzprofil}</td>
                        </tr>
                    }
                    {stelle?.firma.plz != null &&
                        <tr>
                            <td>PLZ:</td>
                            <td>{stelle?.firma.plz}</td>
                        </tr>
                    }
                    {stelle?.firma.ort != null &&
                        <tr>
                            <td>Ort:</td>
                            <td>{stelle?.firma.ort}</td>
                        </tr>
                    }
                    {stelle?.firma.strasse != null &&
                        <tr>
                            <td>Straße:</td>
                            <td>{stelle?.firma.strasse}</td>
                        </tr>
                    }
                    {stelle?.firma.web != null &&
                        <tr>
                            <td>Web:</td>
                            <td>{stelle?.firma.web}</td>
                        </tr>
                    }
                    </tbody>
                </table>


            </Fieldset>

        </div>
    );
}

export default DetailsAusbildungsstelle;
