import React, {useEffect, useState} from 'react';
import './App.css';
import {FilterMatchMode, PrimeReactProvider} from "primereact/api";
import {DataTable, DataTableFilterMeta, DataTableSelectEvent} from "primereact/datatable";
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {settings} from "./settings";
import {Stellenangebot} from "./types";
import {useNavigate} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";

function App() {
    const [selectedStellenangebot, setSelectedStellenangebot] = useState<Stellenangebot>();
    const [ausbildungsstellen, setAusbildungsstellen] = useState<Stellenangebot[]>([]);
    const [praktika, setPraktika] = useState<Stellenangebot[]>([]);
    const [dualeStudiengaenge, setDualeStudiengaenge] = useState<Stellenangebot[]>([]);
    const [schuelerpraktika, setSchuelerpraktika] = useState<Stellenangebot[]>([]);
    const [stellen, setStellen] = useState<Stellenangebot[]>([]);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const navigate = useNavigate();
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
    });


    useEffect(() => {
        if (sessionStorage.getItem('searchString')) {
           const searchString = sessionStorage.getItem('searchString');
           // @ts-ignore
            setGlobalFilterValue(searchString);
            let _filters = {...filters};
            // @ts-ignore
            _filters['global'].value = searchString;
            setFilters(_filters);
        }


        fetch(settings.api.url + '/api/stellenangebote/public')
            .then((response) => response.json())
            .then((data) => {
                setStellen(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        // Ausbildungsstellen
        fetch(settings.api.url + '/api/ausbildungsstellen/0/public')
            .then((response) => response.json())
            .then((data) => {
                setAusbildungsstellen(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        // Praktika
        fetch(settings.api.url + '/api/ausbildungsstellen/1/public')
            .then((response) => response.json())
            .then((data) => {
                setPraktika(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        // Duale Studiengänge
        fetch(settings.api.url + '/api/ausbildungsstellen/3/public')
            .then((response) => response.json())
            .then((data) => {
                setDualeStudiengaenge(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        // Schülerpraktika
        fetch(settings.api.url + '/api/ausbildungsstellen/2/public')
            .then((response) => response.json())
            .then((data) => {
                setSchuelerpraktika(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        sessionStorage.setItem('searchString', value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Stichwortsuche (Bezeichnung, Firma, Berufsfeld/Praktikum)..." className="search-field"/>
                </span>
            </div>
        );
    };

    useEffect(() => {
        if (selectedStellenangebot) {
            if (selectedStellenangebot.type === 'stellenangebot') {
                navigate('/details/' + selectedStellenangebot.id);
            } else {
                navigate('/ausbildungstelle/details/' + selectedStellenangebot.id);
            }
        }
    });


    const onRowSelect = (event: DataTableSelectEvent) => {
        setSelectedStellenangebot(event.data);
    };


    const header = renderHeader();

    return (
        <div className="App">
            <TabView>
                <TabPanel header="Ausbildungsstellen">
                    <PrimeReactProvider>
                        <DataTable
                            value={ausbildungsstellen}
                            stripedRows
                            dataKey="id"
                            filterDisplay="menu"
                            header={header}
                            selectionMode="single"
                            onRowSelect={onRowSelect}
                            filters={filters}
                            globalFilterFields={['titel', 'firma_name', 'ausbildungsbeginn', 'ausbildungsort', 'berufsfeld.titel']}
                            emptyMessage="Keine Stellen gefunden.">
                            <Column field="titel" header="Bezeichnung" sortable className="title-column"></Column>
                            <Column field="firma_name" header="Unternehmen" sortable></Column>
                            <Column field="ausbildungsbeginn" header="Beginn" sortable></Column>
                            <Column field="ausbildungsort" header="Ort" sortable></Column>
                        </DataTable>

                    </PrimeReactProvider>
                </TabPanel>
                <TabPanel header="Praktika">
                    <PrimeReactProvider>
                        <DataTable
                            value={praktika}
                            stripedRows
                            dataKey="id"
                            filterDisplay="menu"
                            header={header}
                            selectionMode="single"
                            onRowSelect={onRowSelect}
                            filters={filters}
                            globalFilterFields={['titel', 'firma_name', 'ausbildungsbeginn', 'ausbildungsort', 'berufsfeld.titel']}
                            emptyMessage="Keine Stellen gefunden.">
                            <Column field="titel" header="Bezeichnung" sortable className="title-column"></Column>
                            <Column field="firma_name" header="Unternehmen" sortable></Column>
                            <Column field="ausbildungsbeginn" header="Beginn" sortable></Column>
                            <Column field="ausbildungsort" header="Ort" sortable></Column>
                        </DataTable>

                    </PrimeReactProvider>
                </TabPanel>
                <TabPanel header="Schülerpraktikum">
                    <PrimeReactProvider>
                        <DataTable
                            value={schuelerpraktika}
                            stripedRows
                            dataKey="id"
                            filterDisplay="menu"
                            header={header}
                            selectionMode="single"
                            onRowSelect={onRowSelect}
                            filters={filters}
                            globalFilterFields={['titel', 'firma_name', 'ausbildungsbeginn', 'ausbildungsort', 'berufsfeld.titel']}
                            emptyMessage="Keine Stellen gefunden.">
                            <Column field="titel" header="Bezeichnung" sortable className="title-column"></Column>
                            <Column field="firma_name" header="Unternehmen" sortable></Column>
                            <Column field="ausbildungsbeginn" header="Beginn" sortable></Column>
                            <Column field="ausbildungsort" header="Ort" sortable></Column>
                        </DataTable>

                    </PrimeReactProvider>
                </TabPanel>
                <TabPanel header="Duales Studium">
                    <PrimeReactProvider>
                        <DataTable
                            value={dualeStudiengaenge}
                            stripedRows
                            dataKey="id"
                            filterDisplay="menu"
                            header={header}
                            selectionMode="single"
                            onRowSelect={onRowSelect}
                            filters={filters}
                            globalFilterFields={['titel', 'firma_name', 'ausbildungsbeginn', 'ausbildungsort', 'berufsfeld.titel']}
                            emptyMessage="Keine Stellen gefunden.">
                            <Column field="titel" header="Bezeichnung" sortable className="title-column"></Column>
                            <Column field="firma_name" header="Unternehmen" sortable></Column>
                            <Column field="ausbildungsbeginn" header="Beginn" sortable></Column>
                            <Column field="ausbildungsort" header="Ort" sortable></Column>
                        </DataTable>

                    </PrimeReactProvider>
                </TabPanel>
                <TabPanel header="Stellenangebote">
                    <PrimeReactProvider>
                        <DataTable
                            value={stellen}
                            stripedRows
                            dataKey="id"
                            filterDisplay="menu"
                            header={header}
                            selectionMode="single"
                            onRowSelect={onRowSelect}
                            filters={filters}
                            globalFilterFields={['bezeichnung', 'firma_name', 'beginn', 'ort', 'berufsfeld.titel']}
                            emptyMessage="Keine Stellen gefunden.">
                            <Column field="bezeichnung" header="Bezeichnung" sortable></Column>
                            <Column field="firma_name" header="Unternehmen" sortable></Column>
                            <Column field="beginn" header="Beginn" sortable></Column>
                            <Column field="ort" header="Ort" sortable></Column>
                        </DataTable>

                    </PrimeReactProvider>
                </TabPanel>
            </TabView>

        </div>
    );
}

export default App;
